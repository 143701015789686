
/* laptops and tablets media query*/
@media only screen and (min-width: 1000px) {
   /* Tabs */
.tabMain{
 display: inline;
}

.tabMain a{
    color:black;
    display: inline;
    font-weight: bold;
    border-bottom: 3px solid gray;
    opacity: 60%;
}

.tabMain a:focus{
    border-bottom: 3px solid #ffd11a !important;
}

/* wrapper for horizontal scroll */
.wrapper{
    display: inline-block;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space:nowrap;
    width: 900px;
    height: 400px;
    padding-top: 5px;
}

/* Card Item inside of wrapper */
.wrapper .item{
    height: 20rem;
    width: 20rem;
    border-radius: 15px;
    box-shadow:0 4px 6px -6px #222 ;
    display: inline-block;
    margin: 8px;

}

/* Card image size */
.cardImg{
    height:15rem;
    width: 20rem;
}

 /* Scroll style */
::-webkit-scrollbar-thumb {
  background-color: #ffd11a;
  border-radius: 20px;

}

::-webkit-scrollbar {
  height: 4px;           
  width: 4px;               
  border: 1px solid #ffd11a;
} 

}

@media only screen and (max-width: 800px) {
/* Tabs */
.tabMain{
 display: inline;
}

.tabMain a{
    color:black;
    display: inline;
    font-weight: bold;
    border-bottom: 3px solid gray;
    opacity: 60%;
}

.tabMain a:focus{
    border-bottom: 3px solid #ffd11a !important;
}

/* wrapper for horizontal scroll */
.wrapper{
    display: inline-block;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space:nowrap;
    width: 700px;
    height: 400px;
    padding-top: 5px;
}

/* Card Item inside of wrapper */
.wrapper .item{
    height: 20rem;
    width: 20rem;
    border-radius: 15px;
    box-shadow:0 4px 6px -6px #222 ;
    display: inline-block;
    margin: 8px;

}

/* Card image size */
.cardImg{
    height:15rem;
    width: 20rem;
}

 /* Scroll style */
::-webkit-scrollbar-thumb {
  background-color: #ffd11a;
  border-radius: 20px;

}

::-webkit-scrollbar {
  height: 4px;           
  width: 4px;               
  border: 1px solid #ffd11a;
}

 } 

/* Mobile device media query */
@media only screen and (max-width: 500px) {
    /* phones */
    .tabMain{
 display: inline;
}

.tabMain a{
    color:black;
    display: inline;
    font-weight: bold;
    border-bottom: 3px solid gray;
    opacity: 60%;
}

.tabMain a:focus{
    border-bottom: 3px solid #ffd11a !important;
}

.wrapper{
    display: inline-block;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space:nowrap;
    width: 300px;
    height: 300px;
    padding-top: 5px;
}

.wrapper .item{
    width: 15rem;
    border-radius: 15px;
    box-shadow:0 4px 6px -6px #222 ;
    display: inline-block;
    margin: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #ffd11a;
  border-radius: 20px;
}

.cardImg{
    height:10rem;
    width: 15rem;
}

::-webkit-scrollbar {
  height: 4px;           
  width: 4px;               
  border: 1px solid #ffd11a;
}
 } 
