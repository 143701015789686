/*Background Image*/
body{
  background-color: #FFCC00;
  /* background-color: #F2A33C */
}
.background {
    background-repeat: no-repeat;
    height:115vh;
    width:auto;
    /* background-image:url("../assets/sven-brandsma-qRsK1iwCe1o-unsplash.png");     */
    color: #333;
    background-size: cover;
    margin: 0;
    background-position: center;
    /* box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5); */
    box-shadow: inset 0 0 10rem rgba(0, 0, 0, 1);
    font-size: 18px;
    font-weight: bold;
  }
  
  .body{
    padding-top: 15%;
    padding-left: 15%;
  }
  
  .logo{
    width: 90%;
    text-align: center;
  }
  
  .pushFooter{
    height: 100vh;
  }
  
  .socialMedia{
    font-size: 36px;
    text-align: right;
  }
  
  .footer{
    text-align: center;
    
  }
  .row, .col{
    border: 2px, solid, #fff;
  }

  .phoneCenter{
    padding-top: 10%;
    text-align: center;
  }

  .appleButton{
    display: inline-block; 
    overflow: hidden; 
    border-radius: 13px; width: 250px; height: 64px;
  }
  .googleButton{
    display: inline-block; 
    overflow: hidden; 
    border-radius: 13px;  height: 64px;
  }
  .productHunt{
    display:inline-block;
    overflow:hidden;
    background:url("https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=394716&theme=dark") no-repeat;
    width:250px;
    height:64px;
    
  }
 
 .header{
   width:100%;
 }
 .gftlogo{
   width:145px;
   height:145px;
 }
  
  /* iPhone 14 Pro */
  @media (min-width: 390px) and (max-height: 844px) and (orientation: portrait) {
    .background {
      background-repeat: no-repeat;
      height:105vh;
      width:auto;
      /* background-image:url("../assets/sven-brandsma-qRsK1iwCe1o-unsplash.png");     */
      color: #333;
      background-size: cover;
      margin: 0;
      background-position: center;
      /* box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5); */
      box-shadow: inset 0 0 1rem rgba(0, 0, 0, 1);
      font-size: 18px;
      font-weight: bold;
      overflow: hidden;
    }
    
    .body{
      padding-top: 5%;
      padding-left: 5%;
    }
    
    .logo{
      width: 90%;
      text-align: center;
    }
    
    .pushFooter{
      height: 80vh;
    }
    
    .hide{
      visibility: hidden;
      overflow: hidden;
      display: none;
    }
    
    .socialMedia{
      font-size: 36px;
      text-align: center;
    }
    
   }
  /* iPhone Pro 14 MAX */
  @media only screen 
  and (device-width: 428px) 
  and (device-height: 926px) 
  and (-webkit-device-pixel-ratio: 3) {
    
   }
  
  /* Mobile S View*/
  @media (min-width: 320px) {
    .logoImg{
      position: relative;
      height: 190px;
      width: auto;
      margin-top:50%;
  }
  .phone{
      height: 700px;
  }
  .headerVideo{
      position: relative;
      height:190px;
      width: auto;
      margin-top:0px;
  }
  }
  
    /* Mobile M View*/
  @media (min-width: 375px) and (max-height: 667px) and (orientation: portrait) {
    .background {
    background-repeat: no-repeat;
    height:120vh;
    width:auto;
    /* background-image:url("../assets/sven-brandsma-qRsK1iwCe1o-unsplash.png");     */
    color: #333;
    background-size: cover;
    margin: 0;
    background-position: center;
    /* box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5); */
    box-shadow: inset 0 0 1rem rgba(0, 0, 0, 1);
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
  }
  
  .body{
    padding-top: 5%;
    padding-left: 5%;
  }
  
  .logo{
    width: 90%;
    text-align: center;
  }
  
  .pushFooter{
    height: 95vh;
  }
  
  .hide{
    visibility: hidden;
    overflow: hidden;
    display: none;
  }
  
  .socialMedia{
    font-size: 36px;
    text-align: center;
  }
  
  }

  /*Tablet View*/
  @media (min-width: 700px) {
    .logoImg{
      position: relative;
      height:450px;
      width: auto;
      margin-top:40px;
  }
  .headerVideo{
      position: relative;
      height:450px;
      width: auto;
      margin-top:0px;
  }
  }
  
  /*Laptop View*/
  @media (min-width: 1280px) {
 .background {
    background-repeat: no-repeat;
    height:100vh;
    width:auto;
    /* background-image:url("../assets/sven-brandsma-qRsK1iwCe1o-unsplash.png");     */
    color: #333;
    background-size: cover;
    margin: 0;
    background-position: center;
    /* box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5); */
    box-shadow: inset 0 0 5rem rgba(0, 0, 0, 1);
    font-size: 18px;
    font-weight: bold;
    overflow: hidden;
  }
  
  .body{
    padding-top: 5%;
    padding-left: 5%;
  }
  
  .logo{
    width: 80%;
    text-align: center;
  }
  
  .pushFooter{
    height: 85vh;
  }
  
  .phone{
    height: 70vh;
  }
  
  .socialMedia{
    font-size: 36px;
    text-align: center;
  }
  
  }

  